@import '../../styles/customMediaQueries.css';
.pageRoot {
  position: relative;
}

.sticky {
}
.heroContainer {
  padding: 0 24px 86px;
  background-color: var(--matterPurpleColor);
  position: relative;
  overflow: hidden;
  @media (--viewportMedium) {
    padding: 0 24px 150px;
  }
  & .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.5;
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .shape2 {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    opacity: 0.5;
    & > img {
      max-width: 100%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .shape3 {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    text-align: right;
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
}
.contentWidth {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.pageContent {
  width: 100%;
  padding: 40px 60px;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  & .heroContainer {
    & .contentWidth {
      padding-top: 130px;
      position: relative;
      z-index: 1;
      @media (--viewportMedium) {
        padding-top: 170px;
      }
    }
  }
}
.heroContent {
  text-align: center;
  max-width: 752px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  & > img {
    opacity: 0.6;
    align-self: center;
  }
  & > h1 {
    color: var(--colorWhite);
    font-weight: var(--fontWeightBold);
    font-size: 35px;
    line-height: 48px;
    margin: 20px auto;
    width: 100%;
    max-width: 667px;
    @media (--viewportLarge) {
      font-size: 36px;
      line-height: 68px;
    }
  }
  & > p {
    color: var(--colorWhite);
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 25px;
    margin: 20px auto;
    width: 100%;
    max-width: 586px;
    @media (--viewportLarge) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  & .registerNowBtn {
    & .registerBtn {
      background-color: var(--colorWhite);
      border: solid 1px var(--colorWhite);
      color: var(--marketplaceColor);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-weight: var(--fontWeightSemiBold);
      font-size: 14px;
      line-height: 21px;
      /* text-transform: uppercase; */
      cursor: pointer;
      padding: 14px 20px;
      transition: var(--transitionStyle);
      min-width: 250px;
      margin-top: 50px;
      &:hover {
        background-color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
        color: var(--colorWhite);
        transition: var(--transitionStyle);
      }
    }
  }
}
.ourStoryContainer {
  width: 100%;
  padding: 40px 24px;
  @media (--viewportMedium) {
    padding: 65px 24px;
  }
  & .ourStoryContent {
    text-align: center;
    & > h1 {
      color: var(--marketplaceColor);
      font-weight: var(--fontWeightBold);
      font-size: 25px;
      line-height: 28px;
      margin: 0px auto 20px;
      /* text-transform: uppercase; */
      width: 100%;
      max-width: 524px;
      text-align: center;

      @media (--viewportMedium) {
        font-size: 35px;
        line-height: 48px;
      }
      @media (--viewportLarge) {
        font-size: 36px;
        line-height: 68px;
      }
    }
    & > p {
      margin: 0 0 0 0;
      font-weight: var(--fontWeightRegular);
      font-size: 15px;
      line-height: 20px;
      color: var(--colorBlack);
      @media (--viewportMedium) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.ourTeamContainer {
  background-color: var(--matterLightColor);
  width: 100%;
  padding: 40px 24px;
  margin: 50px 0 0;
  position: relative;
  @media (--viewportMedium) {
    padding: 65px 24px;
  }
  & .shape1 {
    position: absolute;
    left: 20px;
    top: -40px;
    opacity: 1;
    text-align: left;
    z-index: 0;
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .shape2 {
    position: absolute;
    right: 0px;
    top: -40px;
    text-align: right;
    z-index: 0;
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .shape3 {
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: left;
    display: none;
    z-index: 0;
    @media (--viewportMedium) {
      display: block;
    }
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .shape4 {
    position: absolute;
    right: 0;
    bottom: 10px;
    text-align: right;
    display: none;
    z-index: 0;
    @media (--viewportMedium) {
      display: block;
    }
    & > img {
      max-width: 50%;
      @media (--viewportMedium) {
        max-width: 100%;
      }
    }
  }
  & .ourTeamContent {
    text-align: center;
    position: relative;
    z-index: 1;
    & > h1 {
      color: var(--matterGreenColor);
      font-weight: var(--fontWeightBold);
      font-size: 25px;
      line-height: 28px;
      margin: 0px auto 30px;
      /* text-transform: uppercase; */
      width: 100%;
      max-width: 524px;
      text-align: center;

      @media (--viewportMedium) {
        font-size: 35px;
        line-height: 48px;
      }
      @media (--viewportLarge) {
        font-size: 36px;
        line-height: 68px;
      }
    }
    & > p {
      margin: 0 0 0 0;
      font-weight: var(--fontWeightRegular);
      font-size: 15px;
      line-height: 20px;
      color: var(--colorBlack);
      @media (--viewportMedium) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    & .teamBlock {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 50px;
      @media (--viewportMedium) {
        grid-template-columns: repeat(3, 1fr);
      }
      & .team {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .teamInfo {
          & .teamImg {
            width: 100px;
            height: 100px;
            border: 5px solid var(--marketplaceColor);
            overflow: hidden;
            border-radius: 100px;
            margin: 0 auto 27px;
            & > img {
              max-width: 100%;
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: top;
            }
          }
          & > h2 {
            font-weight: var(--fontWeightBold);
            font-size: 14px;
            line-height: 21px;
            color: var(--matterGreenColor);
            margin: 0 0 0 0;
            padding: 0;
          }
          & > h3 {
            font-weight: var(--fontWeightMedium);
            font-size: 12px;
            line-height: 18px;
            color: var(--colorBlack);
            margin: 0 0 10px 0;
            padding: 0;
          }
          & > p {
            font-weight: var(--fontWeightRegular);
            font-size: 12px;
            line-height: 15px;
            color: var(--colorBlack);
            margin: 0 0 20px 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.title {
  & > span {
    color: #588158;
  }
}

.websiteUrl {
  display: flex;
  justify-content: center;
  gap: 10px;
  & > a {
    & > svg {
      fill: none;
    }
  }
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;

  & > span {
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 4px 20px;
  }
}
.active {
  border: 1px solid #ee8b51 !important;
  background: #ee8b51;
  color: white;
  cursor: pointer;
}
